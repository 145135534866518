import { Layout } from '../components/layout';
import { HeadInner } from '../components/Head';
import { StaticImage } from 'gatsby-plugin-image';

export const Head = () => <HeadInner path='/404' title='404' noindex />;

const NotFoundPage = () => {
  return (
    <Layout isBottomMenu={false}>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 48,
          padding: '100px 17px',
        }}
      >
        <StaticImage
          src='../images/paild_404.png'
          alt='404 notfound'
          quality={100}
          placeholder='none'
          css={{ maxWidth: 640 }}
        />
        <p>ページが見つかりませんでした。</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
